<template>
  <div id="app">
    <v-app  v-if="isScreenLargerThan1170">
      <img src="@/assets/404.jpg"/>
      <div class="rounded-xl right">
        <div class="content">
          <h1 class="pb-5 text-center logo project-logo">RiverTemp</h1>
          <h2 class="text-center">
            <span class="white--text text-h2">404</span>
            <br /> 
            We are sorry
            <br />
            This page does not exist.
          </h2>
          <div class="form-element d-flex justify-center">
            <button 
              id="goRight" 
              class="login text-capitalize" 
              name="signup" 
              @click="$router.go(-1)"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </v-app>
    <div v-else>
      <div>
        <div>
          <h1 class="pb-5 text-center white--text logo small-screen">RiverTemp</h1>
          <h2 class="text-center small-screen ">
            <span class="white--text text-h2">404</span>
            <br /> 
            We are sorry
            <br />
            This page does not exist.
          </h2>
          <div class="form-element d-flex justify-center">
            <button 
              id="goRight" 
              class="login text-capitalize" 
              name="signup" 
              @click="$router.go(-1)"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PageNotFound",

    data(){
      return{
        screenWidth: window.innerWidth,
      }
    },

    computed:{

      isScreenLargerThan1170(){
        return this.screenWidth > 1170;
      }
    },

    methods: {
        hasHistory () { return window.history.length > 2 },
        goBack () {
            if (this.hasHistory()) {
                $router.go(-1);
                return;
            }
            $router.push('/')
        }
    }
  }
</script>

<style scoped lang="scss">
 @import "@/plugins/login.scss";
  .project-logo{
   color:white;
  }
  .form-label{
   font-family: 'Rubik', sans-serif;
   font-size: 20px;
  }
  img{
   display: flex;
   justify-content: flex-end;
   height: 100%;
   width: 100%;
   padding: 0;
  }
  .logo{
   font-family: 'Rubik', sans-serif;
   font-size: 80px;
  }

  h2{
    color: white !important;
    font-family: 'Rubik', sans-serif;
  }
  .right {
    height: 40%;
    display: flex;
    right: 25%;
    margin-top: 10%;
    background-color: rgba(89, 91, 93, 0.599);
  }
  ::v-deep .v-application--wrap{
    position: fixed !important;
  }
  .small-screen{
    background-color: #19a7cecd;
  }
</style>